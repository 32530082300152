$(function () {
    // ACCORDION TOGGLE 
    if($('.accordion-row').length > 0) 
    {
        $('.accordion-row').each(function(){ 
            var dt = $(this).find('.accordion-head'),
                dd = $(this).find('.accordion-info');
            dd.hide();
            dt.click(function(){
                if($(this).hasClass('active')){  
                    dd.slideUp();
                    dt.removeClass('active');   
                } else{
                    dd.slideUp();
                    dt.removeClass('active'); 
                    $(this).addClass('active').next('.accordion-info').slideDown();
                } 
            });					
        }); 
    }

    // ACCORDION Toggle (Multiple Items)   
    if($('.acc .acc-head').length > 0)
    {
        $('.acc .acc-head').click(function(){
            var parent = $(this).parent();        
            parent.toggleClass('acc-active');
            parent.find('.acc-info').stop().slideToggle(); 
        });
    }
}); 



