$(function(){   

    $('.slideSet').flickity({
        // options
        cellAlign: 'left',               
        wrapAround: true,    
        groupCells: false,
        contain: true,
        pageDots: true,
        prevNextButtons: true,  
        imagesLoaded:true,
        adaptiveHeight: true,
    });

    if($('.slideSetAuto').length > 0)
	{
        $('.slideSetAuto').flickity({
        // options
            cellAlign: 'left',               
            wrapAround: true,    
            groupCells: false,
            contain: true,
            pageDots: true,
            prevNextButtons: true,  
            imagesLoaded:true,
            adaptiveHeight: true, 
            autoPlay: true,
        });
    }; 
    

    if($('.single-slider').length > 0)
	{
        $('.single-slider').flickity({ 
            cellAlign: 'left',               
            wrapAround: true,    
            groupCells: false,
            contain: true,
            pageDots: false,
            prevNextButtons: true,  
            imagesLoaded:true,
            adaptiveHeight: true,
        });

        $('.modal').on('shown.bs.modal', function (e) {
            $(".single-slider").flickity('resize');
            console.log('shown') 
        });
    };

    if($('.groupSlider').length > 0)
	{
        $('.groupSlider').flickity({
            cellAlign: 'center',
            wrapAround: false,
            groupCells: "true",
            contain: true,
            pageDots: true,
            prevNextButtons: true,
            imagesLoaded: true,
            adaptiveHeight: true,
        });
    };

    if($('.groupLogoSlider').length > 0)
    {
        $('.groupLogoSlider').flickity({
            cellAlign: 'center',
            wrapAround: false,
            //groupCells: 'true',
            contain: true,
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
            adaptiveHeight: true,
            autoPlay: 1000,
            pauseAutoPlayOnHover: false,
        });
    };
});



          












